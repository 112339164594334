<template>
  <div>
    <div @click="changePanel" style="margin-bottom: 5px;">
      <span :class="{ active: !isSignUp }">{{ $t('user.sign_in') }}</span> |
      <span :class="{ active: isSignUp }">{{ $t('user.sign_up') }}</span>
    </div>

    <div>
      <label for="email">{{ $t('user.email') }}</label>
      <input type="email" id="email" v-model="email">
    </div>
    <div>
      <label for="password">{{ $t('user.password') }}</label>
      <input type="password" id="password" v-model="password">
    </div>
    <div v-if="isSignUp">
      <div>
        <label for="password-confirmation">{{ $t('user.password_confirmation') }}</label>
        <input type="password" id="password-confirmation" v-model="passwordConfirmation">
      </div>
      <div>
        <label for="name">{{ $t('user.nickname') }}</label>
        <input type="text" id="name" v-model="name">
      </div>
    </div>
    <div>
      <button @click="sign">
        <span v-if="isSignUp">{{ $t('user.sign_up') }}</span>
        <span v-else>{{ $t('user.sign_in') }}</span>
      </button>
      <button @click="forgetPassword">{{ $t('user.forget_password') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sign',
  data: () => ({
    isSignUp: false,
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (this.user.token !== null) {
      this.returnLastPath();
    }
  },
  methods: {
    // 注册或登录
    sign() {
      // 检查
      const params = {};
      let uri = 'api/login';
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!this.email || !emailRegex.test(String(this.email).toLowerCase())) {
        return this.$tip.fire(this.$t('default.need_email', { attribute: this.$t('user.email') }));
      }
      if (this.email.length > 255) {
        return this.$tip.fire(this.$t('default.limit_length', { attribute: this.$t('user.email'), min: 1, max: 255 }));
      }
      params.email = this.email;

      if (!this.password || this.password.length > 32) {
        return this.$tip.fire(this.$t('default.limit_length', { attribute: this.$t('user.password'), min: 1, max: 32 }));
      }
      params.password = this.password;

      if (this.isSignUp) {
        uri = 'api/register';

        if (!this.name && this.name.length > 20) {
          return this.$tip.fire(this.$t('default.limit_length', { attribute: this.$t('user.nickname'), min: 1, max: 20 }));
        }
        params.name = this.name;

        if (this.passwordConfirmation !== this.password) {
          return this.$tip.fire(this.$t('default.need_confirmed', { attribute: this.$t('user.password') }));
        }
        params.password_confirmation = this.passwordConfirmation;
      }
      // 发送
      return this.$http.post(uri, params)
        .then((response) => {
          const { data } = response;
          this.$store.commit('setUser', data);
          this.returnLastPath();
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 前去找回密码
    forgetPassword() {
      this.$router.push('/forget-password');
    },
    // 切换登录或注册
    changePanel() {
      this.isSignUp = !this.isSignUp;
    },
    // 返回上层
    returnLastPath() {
      const lastURI = localStorage.getItem('lastURI');
      if (lastURI) {
        localStorage.removeItem('lastURI');
        this.$router.push(lastURI);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: #9de8bd;
  padding: 4px;
}
</style>
